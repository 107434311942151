import React, { useMemo, useEffect, useState } from "react"

import { useCore } from "../../hooks/useCore"
import { useApp } from "../../hooks/useApp"
import { useSanityEvent } from "../../hooks/useSanity"

export const withEventListing = Component =>
  React.memo(({ name = "EventListing", page, events, live, loading, ...props }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { ready } = useApp()
    const { normalizeEvent } = useSanityEvent()
    const [loaded, setLoaded] = useState(false)

    const title = page?.title
    const content = sanityContent(page?.content)
    const today = new Date()

    useEffect(() => {
      if (live?.length && !loading) setLoaded(true)
    }, [live, loading])

    const normalisedEvents = events?.edges?.map(normalizeEvent)
    const normalisedEventsPast = [...normalisedEvents].reverse()

    const items = normalisedEvents
      ?.filter(item => {
        const date = item.date && new Date(item.date)
        return date && date.getTime() >= today.getTime()
      })
      .map(event => ({
        ...event,
        attending: live?.length ? live?.filter(item => item?._id == event?.id)?.[0]?.attending : event?.attending,
        freeEvent: live?.length ? live?.filter(item => item?._id == event?.id)?.[0]?.freeevent : event?.freeevent,
      }))

    const itemsPast = normalisedEventsPast?.filter(item => {
      const date = item.date && new Date(item.date)
      return date && date.getTime() < today.getTime()
    })

    Component.displayName = name
    return useMemo(() => <Component {...props} {...page} title={title} content={content} events={items} eventsPast={itemsPast} loading={!loaded} />, [
      ready,
      live,
      loaded,
      items,
      itemsPast,
    ])
  })
