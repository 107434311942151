import React from "react"
import { graphql } from "gatsby"

import { EventListing as Page } from "../components/Event/EventListing"
import { useSanityEvent } from "../hooks/useSanity"
import { useApp } from "../hooks/useApp"

export const query = graphql`
  query {
    page: sanityPageEvents {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      additionalAll
      additionalUpcoming
      additionalPast
      additionalUpcomingEmpty
      additionalPastEmpty
    }
    events: allSanityEvent(filter: { attributes: { publishedAt: { ne: null } } }, sort: { fields: date, order: ASC }) {
      edges {
        node {
          ...GatsbyEventFragment
        }
      }
    }
    # eventsPast: allSanityEvent(filter: { attributes: { publishedAt: { ne: null } } }, sort: { fields: date, order: DESC }) {
    #   edges {
    #     node {
    #       ...GatsbyEventFragment
    #     }
    #   }
    # }
  }
`

export default ({ data, ...props }): JSX.Element => {
  const { ready } = useApp()
  const { events, loadingLiveEvents, getEvents } = useSanityEvent()

  if (ready && !events?.length)
    getEvents({
      ids: data?.events?.edges
        ?.filter(({ node }) => {
          const now = new Date()
          const date = node.date && new Date(node.date)
          return date && date.getTime() >= now.getTime()
        })
        .map(({ node }) => node?.id),
    })

  return <Page {...props} {...data} live={events} loading={loadingLiveEvents} />
}
