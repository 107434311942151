// import React from "react"

// import { withEventListing } from "./withEventListing"
// import { EventCard } from "./EventCard"

// export const EventListing = withEventListing(
//   ({ title, content, articles }): JSX.Element => (
//     <article>
//       <h1>{title}</h1>
//       {content}
//       {articles && articles?.map((article, index) => <EventCard key={index} article={article} />)}
//     </article>
//   )
// )

import React from "react"

import { withEventListing } from "./withEventListing"
import { getFluidGatsbyImage } from "../Wrappers/Helpers"

import { EventCard } from "./EventCard"
import { Tabs } from "../Tabs/Tabs"
import { Container, Responsive, Title, Subtitle, Row, Column, P } from "./EventListing.styled"

export const EventListing = withEventListing(
  ({
    events,
    eventsPast,
    subtitle,
    title,
    loading,
    additionalAll,
    additionalUpcoming,
    additionalPast,
    additionalUpcomingEmpty,
    additionalPastEmpty,
  }: Props): JSX.Element => (
    <>
      <Container topSpacing={`md`} width={`xl`}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Container>

      <Container spacing={`xl`} collapse={`<md`} width={`xl`}>
        <Tabs
          // link={"/events"}
          // linkText={additionalAll}
          items={[
            {
              title: additionalUpcoming,
              content: (
                <Container condense={`<md`}>
                  <Row wrap>
                    {events?.length ? (
                      events?.map(({ attendees, date, image, location, registration, summary, title, attending, ...item }) => (
                        <Column key={item.id} width={`md:w-1/3`}>
                          <Responsive screen={`md>`}>
                            <EventCard
                              size={`full`}
                              content={summary}
                              date={date}
                              image={getFluidGatsbyImage(image, { maxWidth: 800 })}
                              link={`/events/${item?.slug?.current}`}
                              // location={location}
                              members={attendees}
                              registration={registration}
                              title={title}
                              attending={attending}
                              freeEvent={item?.freeEvent}
                              loading={loading}
                              id={item.id}
                            />
                          </Responsive>
                          <Responsive screen={`<md`}>
                            <EventCard
                              size={`full`}
                              date={item.date}
                              content={summary}
                              image={getFluidGatsbyImage(image, { maxWidth: 800 })}
                              link={`/events/${item?.slug?.current}`}
                              members={attendees}
                              title={title}
                              attending={attending}
                              freeEvent={item?.freeEvent}
                              loading={loading}
                              id={item.id}
                            />
                          </Responsive>
                        </Column>
                      ))
                    ) : (
                      <P>{additionalUpcomingEmpty}</P>
                    )}
                  </Row>
                </Container>
              ),
            },
            {
              title: additionalPast,
              content: (
                <Container condense={`<md`}>
                  {eventsPast?.length ? (
                    <Row wrap>
                      {eventsPast?.map(({ attendees, date, image, location, registration, summary, title, attending, ...item }) => (
                        <Column key={item.id} width={`md:w-1/3`}>
                          <Responsive screen={`md>`}>
                            <EventCard
                              size={`full`}
                              content={summary}
                              date={date}
                              image={getFluidGatsbyImage(image, { maxWidth: 800 })}
                              link={`/events/${item?.slug?.current}`}
                              // location={location}
                              members={attendees}
                              registration={registration}
                              title={title}
                              attending={attending}
                              id={item.id}
                            />
                          </Responsive>
                          <Responsive screen={`<md`}>
                            <EventCard
                              size={`full`}
                              content={subtitle}
                              date={date}
                              image={getFluidGatsbyImage(image, { maxWidth: 800 })}
                              link={`/events/${item?.slug?.current}`}
                              members={attendees}
                              title={title}
                              attending={attending}
                              id={item.id}
                            />
                          </Responsive>
                        </Column>
                      ))}
                    </Row>
                  ) : (
                    <P>{additionalPastEmpty}</P>
                  )}
                </Container>
              ),
            },
          ]}
        />
      </Container>
    </>
  )
)

export interface Props {
  events: Array<any>
  eventsPast: Array<any>
  subtitle: string
  title: string
  additionalAll: string
  additionalUpcoming: string
  additionalPast: string
  additionalUpcomingEmpty: string
  additionalPastEmpty: string
}
